// This regexp is basically a duplication of a regexp from `EmailValidator`.
// It would be awesome if we could consolidate them somehow and use only one
// regexp so we can be sure they are always the same.

// In more recent browser versions, the HTML `pattern` attribute forces the
// the 'v' flag, instead of 'u'. This is why we now need to escape certain
// characters when inside brackets, like "-", "|", and "{}".
// https://github.com/whatwg/html/pull/7908
const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+\-\/=?^_`\{\|\}~]+(?:\.[a-zA-Z0-9!#$%&'*+\-\/=?^_`\{\|\}~]+)*@(?:[a-zA-Z0-9\-]+\.)+[a-z]{2,}$/

export {
  EMAIL_REGEX
}
